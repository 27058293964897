export default {
  namespaced: true,
  state: {
    services: []
  },
  getters: {

  },
  mutations: {
    setServices (state, services) {
      state.services = services
    },
    setService (state, service) {
      var serviceObj = state.services.find(x => x.id === service.id)
      Object.assign(serviceObj, service)
    },
    sortDurationOptions (state, id) {
      var serviceObj = state.services.find(x => x.id === id)
      serviceObj.durationOptions = serviceObj.durationOptions.sort((a, b) => {
        return a.duration - b.duration
      })
    }
  },
  actions: {
    get (context) {
      this.$axios.get('/service').then(response => {
        context.commit('setServices', response.data)
      })
    },
    getPublished (context) {
      return this.$axios.get('/service/published').then(response => {
        context.commit('setServices', response.data)
      })
    },
    saveService (context, service) {
      service.duration = parseInt(service.duration)
      service.revocableDuration = parseInt(service.revocableDuration)
      service.price = parseFloat(service.price)

      var request = {
        method: 'post',
        url: '/service',
        headers: {
          'Content-Type': 'application/json'
        },
        data: service
      }

      if (service.id !== undefined) {
        request.method = 'put'
        request.url += '/' + service.id
      }

      return this.$axios(request).then((response) => {
          this._vm.$q.notify({
            color: 'positive',
            icon: 'mdi-checkbox-marked-circle',
            message: 'Erfolgreich'
          })

          context.dispatch('get')
          return response.data
        })
    },
    deleteService (context, id) {
      var request = {
        method: 'delete',
        url: '/service/' + id
      }

      this.$axios(request).then(response => {
        context.dispatch('get')
      })
    },
    deleteResource (context, id) {
      var request = {
        method: 'delete',
        url: '/service/resource/' + id
      }

      return this.$axios(request).then(response => {
        context.commit('setService', response.data)
        return response.data
      })
    },
    saveResource (context, resource) {
      resource.price = parseFloat(resource.price)
      var request = {
        method: 'post',
        url: '/service/resource',
        headers: {
          'Content-Type': 'application/json'
        },
        data: resource
      }

      if (resource.id !== undefined) {
        request.method = 'put'
        request.url += '/' + resource.id
      }

      return this.$axios(request).then((response) => {
        this._vm.$q.notify({
          color: 'positive',
          icon: 'mdi-checkbox-marked-circle',
          message: 'Erfolgreich'
        })

        context.commit('setService', response.data)
        return response.data
      })
    }
  }
}
