import { LocalStorage } from 'quasar'

export default {
  namespaced: true,
  state: {
    user: null,
    token: null
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      LocalStorage.set('token', token)
      this.$axios.defaults.headers.common.Authorization = 'Bearer ' + token
    },
    setUser (state, user) {
      state.user = user
      LocalStorage.set('user', user)
    }
  },
  actions: {
    initialiseStore ({ commit, dispatch }) {
      if (LocalStorage.getItem('token')) {
        commit('setToken', LocalStorage.getItem('token'))
        dispatch('getUser')
      }
    },
    async getUser ({ commit }) {
      var request = {
        method: 'get',
        url: '/user/user'
      }
      return this.$axios(request).then(async (response) => {
        commit('setUser', response.data)
      })
    },
    async getToken ({ commit }, email) {
      var formData = new FormData()
      formData.append('email', email)

      var request = {
        method: 'post',
        url: '/user/token',
        data: formData
      }

      return this.$axios(request).then(async (response) => {
        return response.status === 200
      })
    },
    async login ({ commit }, credentials) {
      var formData = new FormData()
      formData.append('email', credentials.email)
      formData.append('token', credentials.token)

      var request = {
        method: 'post',
        url: '/user/login',
        data: formData
      }

      return this.$axios(request).then(async (response) => {
        if (response.status !== 200 || response.data.token === undefined) {
          return false
        }
        commit('setUser', response.data.user)
        commit('setToken', response.data.token)
        return true
      })
    }
  }
}
