import Vue from 'vue'
import axios from 'axios'

Vue.prototype.$axios = axios

let baseURL = 'https://macher.dev.aepp.ch'
if (process.env.DEV) {
  baseURL = 'http://localhost:5010'
}

const axiosInstance = axios.create({
  baseURL: baseURL
})

export default ({ store, Vue }) => {
  Vue.prototype.$axios = axiosInstance
  store.$axios = axiosInstance
  store.dispatch('auth/initialiseStore')
}
