import Vue from 'vue'

Vue.mixin({
  methods: {
    getTotalCosts () {
      if (this.selectedService === null) {
        return 0
      }
      var serviceCosts = 0
      if (this.selectedDuration) {
        serviceCosts = this.selectedDuration.price
      } else {
        serviceCosts = this.selectedService.price
      }

      var optionCosts = 0
      this.selectedOptions.forEach(option => {
        optionCosts += option.price
      })

      return serviceCosts + optionCosts
    },
    formatPrice (price) {
      return new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(price).replace('.00', '.-')
    },
    redirectIfBooked () {
      if (this.booked) {
        this.$router.push('/')
      }
    },
    formatTime (date) {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }

      var hour = date.getUTCHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      var minute = date.getUTCMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }

      return hour + '.' + minute
    },
    formatDate (date) {
      if (!(date instanceof Date)) {
        date = new Date(date)
      }

      var month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      var day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      return day + '.' + month + '.' + (date.getFullYear())
    }
  },
  computed: {
    services () {
      return this.$store.state.services.services
    },
    loggedIn () {
      return this.$store.state.auth.user !== null
    },
    selectedOptions: {
      get () {
        return this.$store.state.selectedOptions
      },
      set (value) {
        this.$store.commit('setSelectedOptions', value)
      }
    },
    selectedDate: {
      get () {
        return this.$store.state.selectedDate
      },
      set (value) {
        this.$store.commit('setSelectedDate', value)
      }
    },
    selectedTime: {
      get () {
        return this.$store.state.selectedTime
      },
      set (value) {
        this.$store.commit('setSelectedTime', value)
      }
    },
    selectedDuration: {
      get () {
        return this.$store.state.selectedDuration
      },
      set (value) {
        this.$store.commit('setSelectedDuration', value)
      }
    },
    selectedService: {
      get () {
        return this.$store.state.selectedService
      },
      set (value) {
        this.$store.commit('setSelectedService', value)
      }
    },
    booked: {
      get () {
        return this.$store.state.booked
      },
      set (value) {
        this.$store.commit('setBooked', value)
      }
    }
  }
})
