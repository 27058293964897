import Vue from 'vue'
import Vuex from 'vuex'
import services from './services'
import auth from './auth'
import book from './book'

// import example from './module-example'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      services,
      auth,
      book
    },
    state: {
      selectedService: null,
      selectedDate: '',
      selectedTime: '',
      selectedOptions: [],
      selectedDuration: null,
      comment: '',
      booked: false
    },
    mutations: {
      setSelectedDate (state, date) {
        state.selectedDate = date
      },
      setSelectedService (state, service) {
        state.selectedService = service
      },
      setSelectedOptions (state, options) {
        state.selectedOptions = options
      },
      setSelectedTime (state, time) {
        state.selectedTime = time
      },
      setSelectedDuration (state, duration) {
        state.selectedDuration = duration
      },
      setBooked (state, bookingState) {
        state.booked = bookingState
      },
      setComment (state, comment) {
        state.comment = comment
      }
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEBUGGING
  })

  return Store
}
