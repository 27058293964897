export default {
  namespaced: true,
  state: {
    bookings: [],
    unClearedBooking: 0,
    message: ''
  },
  getters: {

  },
  mutations: {
    setMessage (state, message) {
      state.message = message
    },
    setBooking (state, bookings) {
      state.bookings = bookings
    },
    setUnclearedBooking (state, count) {
      state.unClearedBooking = count
    }
  },
  actions: {
    get (context) {
      var request = {
        method: 'get',
        url: '/booking'
      }

      this.$axios(request).then(response => {
        context.commit('setBooking', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    deleteBooking (context, id) {
      var request = {
        method: 'delete',
        url: '/booking/' + id
      }

      return this.$axios(request).then(response => {
        context.dispatch('get')
        return true
      }).catch(error => {
        console.log(error)
        return false
      })
    },
    getUserBooking (context) {
      var request = {
        method: 'get',
        url: '/booking/user'
      }
      this.$axios(request).then(response => {
        context.commit('setBooking', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    getUnclearedBookingCount (context) {
      var request = {
        method: 'get',
        url: '/booking/uncleared'
      }

      this.$axios(request).then(response => {
        context.commit('setUnclearedBooking', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    book (context, { service, date, user, resources, time, durationOption, comment }) {
      user.zip = parseInt(user.zip)

      var resourceIds = resources.map(x => {
        return x.id
      })

      var request = {
        method: 'post',
        url: '/booking',
        data: { serviceId: service.id, date: date, user: user, time: time, resources: resourceIds, durationOption: durationOption, comment: comment }
      }

      return this.$axios(request).then(response => {
        context.commit('setMessage', response.data)
        return true
      }).catch(() => {
        return false
      })
    }
  }
}
